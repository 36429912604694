import { BrowserModule } from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './layout/admin/title/title.component';
import { AuthComponent } from './layout/auth/auth.component';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AdminService } from './service/admin.service';
import {
  NgxUiLoaderModule, NgxUiLoaderService,
} from "ngx-ui-loader";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field' ;

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DatePipe } from '@angular/common';
import {MatSelectModule} from '@angular/material/select';

import { NgxChartsModule }from '@swimlane/ngx-charts';

// import { RecordsListComponent } from './records-list/records-list.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    BreadcrumbsComponent,
    TitleComponent,
    AuthComponent,
    // RecordsListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatSnackBarModule,
    SharedModule,
    NgxUiLoaderModule,
    HttpClientModule,
    MatNativeDateModule,
    MatFormFieldModule,
    FormsModule, 
    ReactiveFormsModule,
    MatSelectModule,
    NgxChartsModule,

    NgxDaterangepickerMd.forRoot()

  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AdminService, multi: true },NgxUiLoaderService, MatNativeDateModule,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
