import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: '',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'consultantManagement',
        short_label: 'c',
        name: 'Consultant Management',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'recordManagement',
        short_label: 'n',
        name: 'Records Management',
        type: 'link',
        icon: 'ti-agenda'
      },
      {
        state: 'formManagement',
        short_label: 'n',
        name: 'Forms Management',
        type: 'link',
        icon: 'ti-notepad'
      },
      {
        state: 'predefinedques',
        short_label: 'n',
        name: 'Pre Defined Questions',
        type: 'link',
        icon: 'ti-notepad'
      },
      {
        state: '',
        short_label: 'n',
        name: 'Performance Management',
        type: 'sub',
        icon: 'ti-bar-chart',
        children: [
          {
            state: 'performanceManagement',
            type: 'link',
            name: 'Performance',
            target: true
          },
          {
            state: 'average',
            type: 'link',
            name: 'Average',
            target: true
          },
          {
            state: 'openclosed',
            type: 'link',
            name: 'Open Closed',
            target: true
          },
          {
            state: 'overdue',
            type: 'link',
            name: 'Overdue',
            target: true
          },
        ]
      },

      {
        state: '',
        short_label: 'n',
        name: 'KPI Records',
        type: 'sub',
        icon: 'ti-bar-chart',
        children: [
            {
              state: 'kpi',
              type: 'link',
              name: 'Consultant Records',
              target: true
            },
            {
              state: 'clients',
              type: 'link',
              name: 'Clients Records',
              target: true
            },
            {
              state: 'riskClassification',
              type: 'link',
              name: 'Risk Classification',
              target: true
            },

          ]
      },


      // {
      //   state: 'reviewmanagement',
      //   short_label: 'n',
      //   name: 'Reviewer',
      //   type: 'link',
      //   icon: 'ti-bar-chart'
      // },
      // {
      //   state: 'createRecord',
      //   short_label: 'n',
      //   name: 'Create Record',
      //   type: 'link',
      //   icon: 'ti-bar-chart'
      // },
      {
        state: 'activityManagement',
        short_label: 'n',
        name: 'Activity Management',
        type: 'link',
        icon: 'ti-layout'
      },
      {
        state: 'changeLog',
        short_label: 'n',
        name: 'Change Log',
        type: 'link',
        icon: 'ti-stats-up'
      },
      // {
      //   state: 'notifications',
      //   short_label: 'n',
      //   name: 'Content Management',
      //   type: 'link',
      //   icon: 'ti-layers'
      // },
      {
        state: 'newContactUs',
        short_label: 'n',
        name: 'Contact Us',
        type: 'link',
        icon: 'ti-layers'
      },
      {
        state: 'contactUs',
        short_label: 'n',
        name: 'Queries',
        type: 'link',
        icon: 'ti-layers'
      },
      {
        state: 'forgetList',
        short_label: 'n',
        name: 'ForgotList',
        type: 'link',
        icon: 'ti-layers'
      },
      {
        state: '',
        short_label: 'n',
        name: 'General Management',
        type: 'sub',
        icon: 'ti-bag',
        children: [
            {
              state: 'terms',
              type: 'link',
              name: 'Terms & Condition',
              target: true
            },
            {
              state: 'privacy',
              type: 'link',
              name: 'Privacy policy',
              target: true
            },
            {
              state: 'aboutUs',
              type: 'link',
              name: 'About Us',
              target: true
            }
          ]
      },
      {
        state: 'reportManagement',
        short_label: 'n',
        name: 'Report Management',
        type: 'link',
        icon: 'ti-book'
      },
    ],
  },
  // {
  //   label: 'Tables',
  //   main: [
  //     {
  //       state: 'bootstrap-table',
  //       short_label: 'B',
  //       name: 'Bootstrap Table',
  //       type: 'link',
  //       icon: 'ti-receipt'
  //     }
  //   ]
  // },
  // {
  //   label: 'Map And Extra Pages ',
  //   main: [
  //     {
  //       state: 'map',
  //       short_label: 'M',
  //       name: 'Maps',
  //       type: 'link',
  //       icon: 'ti-map-alt'
  //     },
  //     {
  //       state: 'authentication',
  //       short_label: 'A',
  //       name: 'Authentication',
  //       type: 'sub',
  //       icon: 'ti-id-badge',
  //       children: [
  //         {
  //           state: 'login',
  //           type: 'link',
  //           name: 'Login',
  //           target: true
  //         }, {
  //           state: 'registration',
  //           type: 'link',
  //           name: 'Registration',
  //           target: true
  //         }
  //       ]
  //     },
  //     {
  //       state: 'user',
  //       short_label: 'U',
  //       name: 'User Profile',
  //       type: 'link',
  //       icon: 'ti-user'
  //     }
  //   ]
  // },
  // {
  //   label: 'Other',
  //   main: [
  //     {
  //       state: '',
  //       short_label: 'M',
  //       name: 'Menu Levels',
  //       type: 'sub',
  //       icon: 'ti-direction-alt',
  //       children: [
  //         {
  //           state: '',
  //           name: 'Menu Level 2.1',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.2',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.2',
  //               target: true
  //             }
  //           ]
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.3',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.4',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.2',
  //               target: true
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       state: 'simple-page',
  //       short_label: 'S',
  //       name: 'Simple Page',
  //       type: 'link',
  //       icon: 'ti-layout-sidebar-left'
  //     }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
