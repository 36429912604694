import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpHandler, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry, tap, finalize } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { environment as env } from "../../environments/environment.prod";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from "ngx-ui-loader";

interface SnackBarMessage {
  message: string;
  action?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  SERVER_URL = environment.SERVER_URL;
  sideBarHeight: number = 0;
  page
  profile
  isLoading: any;
  constructor(private loader: NgxUiLoaderService, private route: ActivatedRoute,
    private router: Router, private http: HttpClient, private _snackBar: MatSnackBar) {

    // alert(env.production)
  }

  getToken() {

    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {
      //  console.log("1 get-token",localStorage.getItem("token"))
      return localStorage.getItem("token")

    } if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "no") {
      // console.log("2 get-token",localStorage.getItem("token"))
      return sessionStorage.getItem("token")

    } else {

      return null

    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    this.loader.start();
    let ok: string;
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.getToken()}`
      }
    });
    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => ok = event instanceof HttpResponse ? 'succeeded' : '',
        // Operation failed; error is an HttpErrorResponse
        error => ok = error
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        this.loader.stop();
        const elapsed = Date.now() - started;
        if (ok == "succeeded") {
          const msg = `${request.method} "${request.urlWithParams}"
           ${ok} in ${elapsed} ms.`;
          console.log(msg);
        } else {
          if (ok["status"] == 401) {
            // logout from here
            localStorage.clear()
            sessionStorage.clear()
            this.router.navigate(['login']);
            console.log(ok["status"]);
          } else {

            
          }
        }

      })
    );
  }

  isAuthenticated() {

    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      return localStorage.getItem('isLogged') == "true"

    } if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "no") {

      return localStorage.getItem('isLogged') == "true"

    } else {

      return false

    }
  }


  loggedUserDetails() {


    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      return JSON.parse(localStorage.getItem("login_form"))

    } if (localStorage.getItem("remember_me")) {

      return JSON.parse(sessionStorage.getItem("login_form"))

    } else {

      return null

    }

  }

  setLoggedUserDetails(object) {

    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      localStorage.setItem("user_details", JSON.stringify(object))

    } if (localStorage.getItem("remember_me")) {


      sessionStorage.setItem("user_details", JSON.stringify(object))

    } else {

    }

  }

  showSuccessMessage(object: SnackBarMessage) {

    this._snackBar.open(object.message, object.action ? object.action : "CLOSE", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });

  }
  showErrorMessage(object: SnackBarMessage) {

    this._snackBar.open(object.message, object.action ? object.action : "CLOSE", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });

  }


  updateLoginDetails(details) { // whenever talent/talent edit name and name will reflect

    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      return localStorage.setItem("user_details", JSON.stringify(details))

    } if (localStorage.getItem("remember_me")) {

      return sessionStorage.setItem("user_details", JSON.stringify(details))

    }

  }

  handleError(error) {
    let errorMessage = '';
    if (error.error && error.error.errors) {
      // client-side error and server side
      if (Array.isArray(error.error.errors.msg)) { // validation error message

        if (error.error.errors.msg.length) {
          const ob = error.error.errors.msg[0]
          if (ob.msg == "IS_EMPTY") {
            errorMessage = `${ob.param} missing`
          } else {
            errorMessage = "Parameters missing"

          }
        }
      } else {
        errorMessage = error.error.errors.msg;
      }
    } else {
      // server-side error

      if (error.status == 401) {
        // Unauthorised 
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/'])
      }

      console.log(error)
      errorMessage = `${error.message}`;
      // errorMessage = `server is not working`;
    }
    this._snackBar.open(errorMessage, "CLOSE", {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
    return throwError(errorMessage);
  }
  getFaqs(): Observable<any> {

    const API_URL = `${this.SERVER_URL}/users/get/ques/ans`
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }


  getPartnerData(data): Observable<any> {


    const API_URL = `${this.SERVER_URL}/users/get/our/patner`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //login
  login_data(data): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/login`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //get_data
  get_data(form): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/getconsultant?${form}`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }

  //get_data
  // get_data(data): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}/admin/getconsultant`
  //   return this.http.post(API_URL, data)
  //     .pipe(
  //       map(res => {
  //         return res;
  //       }),
  //     );
  // }

  //Add_consultant_data
  Add_consultant_data(data): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/addconsultant`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //upload_image
  Upload_image_data(data): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/uploadconsultantdocs`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //Delete_Data
  Delete_Data(data): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/deleteconsultant`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  edit_consultant_data(data): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/editconsultant`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  get_consultant_data(data): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getconsultantid`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //Add_client_data
  Add_client_data(data): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/client`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //get_client
  get_client_data(): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getclient`
    return this.http.post(API_URL, "")
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //service
  get_client_byid(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getclientid`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //generate password
  get_password(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/newpassword`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  get_all_data(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/editclient`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getforms(data:any): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/categorylist?`+`limit=${data.limit}&offset=${data.offset}&search=${data.search}`
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  addforms(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/addcategorylist`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  deleteform(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/deletecategorylist`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  editform(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/editcategorylist`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  viewform(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/addcategoryque`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getform(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getcategoryque`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getprivacy(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/get/cms`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getterms(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/get/cms`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getaboutus(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/get/cms`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  editaboutus(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/edit/cms`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  editterms(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/edit/cms`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  editprivacy(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/edit/cms`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  listactivity(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getactivities`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  gettactivity(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getconsultantactivity`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  gettcontactus(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getcontacts`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  deletecontactus(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/deletecontact`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  replycontactus(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/replycontact`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getreview(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getreviewmanagement`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  deletereview(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/deletereviewmanagement`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  addreview(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/addreviewmanagement`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  editreview(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/editreviewmanagement`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getnewcontact(form): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/getContactInfo`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  editnewcontact(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/editContactInfo`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  
  gettchangelog(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getchangelog`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  contactrow(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getcontactbyid`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  recordview(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getrecordmanagement`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  recorddelete(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/deleterecordmanagement`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getformdata(form): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/getcatqueno`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  getkpirecords(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/kpidetails`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getperformance(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/performancemanagement`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  // deleteperformance(obj): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}/admin/kpidetails`
  //   return this.http.post(API_URL, obj)
  //     .pipe(
  //       map(res => {
  //         return res;
  //       }),
  //     );
  // }
  viewperformance(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/consultantclients`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  kpidashboard(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/dashboardgraph`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  
  addrecord(obj): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/recordcategorylist`;
    console.log(API_URL)
    return this.http.post(API_URL,obj)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  getanswer(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/recordcategoryquestions`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  submitanswer(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/addrecordquestionanswer`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getreviewerr(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/recordreviewmanagement`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  editanswer(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/editrecordquestionanswer`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  gettanswer(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/recordCategoryQuestionsById`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getcsv(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/consultantscsv`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getpass(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/forgotlist`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  assignclient(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/assignclient`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getnotification(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/notification`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  forgotpass(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/adminforgotpassword`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  resetpass(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/resetadmin`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  clientgraphs(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/clientkpigraph`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  clientslist(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/getclient`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  clientgraph(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/clientkpigraph`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  clientriskall(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/clientriskclassification`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  getdashboarlist(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/dashboard`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  openclosedid(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/clientsrecords`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  clientdashboard(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/clientdashboard`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  getclientid(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/clientgraph`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  clientdropdown(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/assignconsultantclients`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  predefinedlisting(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/admin/predefinedrecordquestions`;
    console.log(API_URL)
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res
        })

      )

  }
  editpredefined(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}/admin/editpredefinedque`
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

}
