import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AuthguardGuard } from './adminauth/authguard.guard';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./pages/login/basic-login.module').then(m => m.BasicLoginModule)
  },
  {
    path: 'forgotPswd',
    loadChildren: () => import('./pages/forgot-pswd/forgot-pswd.module').then(m => m.ForgotPswdModule)
  },
  { path: 'resetpassword/:id', loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule) },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }, {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module')
          .then(m => m.DashboardDefaultModule),
          canActivate: [AuthguardGuard]

      }, {
        path: 'consultantManagement',
        loadChildren: () => import('./pages/consultant-management/consultant-management.module')
          .then(m => m.ConsultantManagementModule),
          canActivate: [AuthguardGuard]

      },
      {
        path: 'recordManagement',
        loadChildren: () => import('./pages/record-management/record-management.module')
          .then(m => m.RecordManagementModule),
          canActivate: [AuthguardGuard]
      },
      {
        path: 'formManagement',
        loadChildren: () => import('./pages/form-management/form-management.module').then(m => m.FormManagementModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'performanceManagement',
        loadChildren: () => import('./pages/performance-management/performance-management.module')
        .then(m => m.PerformanceManagementModule),
         canActivate: [AuthguardGuard]
      },

  { path: 'openclosed', loadChildren: () => import('./pages/openclosed/openclosed.module').then(m => m.OpenclosedModule), canActivate: [AuthguardGuard] },

  { path: 'average', loadChildren: () => import('./pages/average/average.module').then(m => m.AverageModule), canActivate: [AuthguardGuard] },

  { path: 'overdue', loadChildren: () => import('./pages/overdue/overdue.module').then(m => m.OverdueModule), canActivate: [AuthguardGuard] },
      {
        path: 'activityManagement',
        loadChildren: () => import('./pages/activity-management/activity-management.module')
        .then(m => m.ActivityManagementModule),
         canActivate: [AuthguardGuard]
      },
      {
        path: 'addConsultant',
        loadChildren: () => import('./pages/add-consultant/add-consultant.module')
        .then(m => m.AddConsultantModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'viewConsultant/:id',
        loadChildren: () => import('./pages/view-consultant/view-consultant.module')
        .then(m => m.ViewConsultantModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'addRecord',
        loadChildren: () => import('./pages/add-record/add-record.module')
        .then(m => m.AddRecordModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'viewForm',
        loadChildren: () => import('./pages/view-form/view-form.module')
        .then(m => m.ViewFormModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'viewPerformance',
        loadChildren: () => import('./pages/view-performance/view-performance.module')
        .then(m => m.ViewPerformanceModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'reviewmanagement',
        loadChildren: () => import('./pages/reviewmanagement/reviewmanagement.module')
        .then(m => m.ReviewmanagementModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'viewActivity',
        loadChildren: () => import('./pages/view-activity/view-activity.module').
        then(m => m.ViewActivityModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'reportManagement',
        loadChildren: () => import('./pages/report-management/report-management.module')
        .then(m => m.ReportManagementModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'terms',
        loadChildren: () => import('./pages/terms/terms.module')
        .then(m => m.TermsModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'privacy',
        loadChildren: () => import('./pages/privacy/privacy.module')
        .then(m => m.PrivacyModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'aboutUs',
        loadChildren: () => import('./pages/about-us/about-us.module')
        .then(m => m.AboutUsModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'contactUs',
        loadChildren: () => import('./pages/contact-us/contact-us.module')
        .then(m => m.ContactUsModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'changeLog',
        loadChildren: () => import('./pages/change-log/change-log.module')
        .then(m => m.ChangeLogModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'user',
        loadChildren: () => import('./pages/user/profile/profile.module')
        .then(m => m.ProfileModule),
        canActivate: [AuthguardGuard]
      },
      {
        path: 'edit-consultant/:id',
        loadChildren: () => import('./pages/edit-consultant/edit-consultant.module')
        .then(m => m.EditConsultantModule),
        canActivate: [AuthguardGuard]
      },
      {
        path:'recordlist',
        loadChildren:()=> import('./records-list/record-list.module')
        .then(m=> m.RecordsListModule),
        canActivate:[AuthguardGuard]
      },
      { path: 'newContactUs',
       loadChildren: () => import('./pages/new-contact-us/new-contact-us.module')
       .then(m => m.NewContactUsModule),
        canActivate:[AuthguardGuard]
      },
      { path: 'viewreply',
       loadChildren: () => import('./pages/viewreply/viewreply.module')
       .then(m => m.ViewreplyModule),
        canActivate:[AuthguardGuard]
      },
      { path: 'kpi',
       loadChildren: () => import('./pages/kpi/kpi.module')
       .then(m => m.KpiModule),
        canActivate:[AuthguardGuard]
      },

      { path: 'riskClassification', 
      loadChildren: () => import('./pages/risk-classification/risk-classification.module')
      .then(m => m.RiskClassificationModule),
      canActivate: [AuthguardGuard] 
    },


      { path: 'clients', loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule) },

      { path: 'createRecord',
       loadChildren: () => import('./pages/creatrecord/creatrecord.module')
       .then(m => m.CreatrecordModule),
        canActivate:[AuthguardGuard]
      },
      { path: 'forgetList', loadChildren: () => import('./pages/forget-list/forget-list.module').then(m => m.ForgetListModule) },

      { path: 'predefinedques', loadChildren: () => import('./pages/predefinedques/predefinedques.module').then(m => m.PredefinedquesModule) },


      { path: 'editRecord', loadChildren: () => import('./pages/edit-record/edit-record.module').then(m => m.EditRecordModule) }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },

  { path: 'newContactUs', loadChildren: () => import('./pages/new-contact-us/new-contact-us.module').then(m => m.NewContactUsModule) },

  { path: 'viewreply', loadChildren: () => import('./pages/viewreply/viewreply.module').then(m => m.ViewreplyModule) },

  { path: 'kpi', loadChildren: () => import('./pages/kpi/kpi.module').then(m => m.KpiModule) },

  { path: 'createrecord', loadChildren: () => import('./createrecord/createrecord.module').then(m => m.CreaterecordModule) },

  { path: 'creatrecord', loadChildren: () => import('./pages/creatrecord/creatrecord.module').then(m => m.CreatrecordModule) },



  // { path: 'db-edit-suggested-video', loadChildren: () => import('./pages/db-edit-suggested-video/db-edit-suggested-video.module').then(m => m.DbEditSuggestedVideoModule) },

  // { path: 'db-edit-suggested-video', loadChildren: () => import('./pages/db-edit-suggested-video/db-edit-suggested-video.module').then(m => m.DbEditSuggestedVideoModule) },



 


  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
